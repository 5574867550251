import axios from "axios";

const API_URL = process.env.REACT_APP_ASSESSMENT_API_URL;

const GetCurrentDateAndTime = () => {
    return axios
        .get(API_URL + "GetCurrentDateAndTime")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetCurrentDateAndTimeFromServer = () => {
    return axios
        .get(API_URL + "GetCurrentDateAndTimeFromServer")
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};



const GetAllAssessmentDetails = () => {
    return axios
        .get(API_URL + "GetAllAssessmentDetails")
        .then((response) => {
            //console.log(response.data.Message);
            sessionStorage.setItem("assessment", JSON.stringify(response.data));
            return response.data;
        });
};
const GetAssessments = () => {
    var value = JSON.parse(sessionStorage.getItem("assessment"));
    if (value == null) {
        axios
            .get(API_URL + "GetAllAssessmentDetails")
            .then((response) => {
                //console.log(response.data.Message);
                sessionStorage.setItem("assessment", JSON.stringify(response.data));
                value = JSON.parse(sessionStorage.getItem("assessment"));
            });
    }

    return value
};
const AddEntryInUserTestTable = (userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "AddEntryInUserTestTable", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
};


const AssignTheAssessmentToUser = (partnerId,userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "AssignTheAssessmentToUser", { params: { partnerId: partnerId, userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
};
const AddUserAssessmentProgress = (userId, assessmentId, topicId, questionId, answerId, startDate, endDate,creditId) => {

    return axios
        .get(API_URL + "AddUserAssessmentProgress", { params: { userId: userId, assessmentId: assessmentId, topicId: topicId, questionId: questionId, answerId: answerId, startTime: startDate, endTime: endDate, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetReviewAnsweredQuestions = (userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "GetReviewAnsweredQuestions", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetCurrentAssessmentSessionValues = (userId, assessmentId,creditId) => {
    return axios
        .get(API_URL + "GetCurrentAssessmentSessionValues", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const SetPendingTimeForTimer = (userId, assessmentId, creditId) => {
    return axios
        .get(API_URL + "SetPendingTimeForTimer", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetPendingTimeForTimer = (userId, assessmentId, creditId) => {
    return axios
        .get(API_URL + "GetTimeForTimer", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId } })
        .then((response) => {
            return response.data;
        });
}

const GetAllAssessmentList = (userId) => {

    return axios
        .get(API_URL + "GetAllAssessmentList", {
            params: {
                userId: userId
            }
        })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetAssessorList = (userId) => {
    return axios
        .get(API_URL + "GetAssessorList", {
            params: {
                userId: userId
            }
        })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetAllAssessmentListByCreditId = (userId, assessmentId, creditId) => {

    return axios
        .get(API_URL + "GetAllAssessmentListByCreditId", {
            params: {
                userId: userId,
                 assessmentId : assessmentId,
                 creditId:creditId
            }
        })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetAllAssessmentDetailsByCreditID = (userId, assessmentId, creditId) => {

    return axios
        .get(API_URL + "GetAllAssessmentDetailsByCreditID", {
            params: {
                userId: userId,
                 assessmentId : assessmentId,
                 creditId:creditId
            }
        })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};


const GetCaseStudyDetailsByAssessmentID = (assessmentId,creditId,userId) => {

    return axios
        .post(API_URL + "GetCaseStudyDetailsByAssessmentID", {

                'assessmentId' : assessmentId,
                'creditId':creditId,
                'userId': userId

        })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetUserUploadFilesList = (caseStudyId,caseStudyCreditId) => {
    return axios
        .get(API_URL + "GetUserUploadFilesList", {params:{
                'caseStudyId' : caseStudyId,
                'caseStudyCreditId':caseStudyCreditId,
        }})
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const AddCaseStudyList = (answer) => {
    return axios
        .post(API_URL + "AddCaseStudyList",{'CaseStudyModels':answer})
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const SaveAssessorAssessmentAnswers = (userId,assessmentId,creditId,questionAnswer,isSave,isPass) => {
    return axios
        .post(API_URL + "SaveAssessorAssessmentAnswers",{'userId':userId,'assessmentId':assessmentId,'creditId':creditId,'questionAnswerModels':questionAnswer,'isSave':isSave,'isPass':isPass})
        .then((response) => {
            return response.data;
        });
};

const GetAssessmentUserDocumentList = (CreditId) => {
    return axios
        .get(API_URL + "GetAssessmentUserDocumentList", { params: { creditId: CreditId} })
        .then((response) => {
            return response.data;
        });
}

const GetAssessorUploadFilesList = (CreditId) => {
    return axios
        .get(API_URL + "GetAssessorUploadFilesList", { params: { creditId: CreditId} })
        .then((response) => {
            return response.data;
        });
}

const GetAssessorQuestionAnswerList = (assessmentId,userId,CreditId) => {
    return axios
        .get(API_URL + "GetAssessorQuestionAnswerList", { params: {assessmentId:assessmentId,userId:userId, creditId: CreditId} })
        .then((response) => {
            return response.data;
        });
}

const GetAssessorUserList = (partnerId) => {
    return axios
        .get(API_URL + "GetAssessorUserList", {params:{ partnerId:partnerId}})
        .then((response) => {
            return response.data;
        });
};

const UpdateUserTestTableNew = (userId, assessmentId, creditId,isOwnCaseStudy) => {
    return axios
        .get(API_URL + "UpdateUserTestTableNew", { params: { userId: userId, assessmentId: assessmentId, creditId: creditId ,isOwnCaseStudy:isOwnCaseStudy} })
        .then((response) => {
            return response.data;
        });
}

const DeleteAssessmentUserDocument = (userDocumentId) => {
    return axios
        .get(API_URL + "DeleteAssessmentUserDocument", { params: { userDocumentId: userDocumentId} })
        .then((response) => {
            return response.data;
        });
}

const DeleteAssessorUploadFile = (userDocumentId) => {
    return axios
        .get(API_URL + "DeleteAssessorUploadFile", { params: { documentId: userDocumentId} })
        .then((response) => {
            return response.data;
        });
}

const UpdateCaseStudy = (userId, assessmentId, linkedAssessmentId,linkedAssessmentCreditId,isOwnCaseStudy) => {
    return axios
        .get(API_URL + "UpdateCaseStudy", { params: { userId: userId, assessmentId: assessmentId, linkedAssessmentId: linkedAssessmentId ,linkedAssessmentCreditId:linkedAssessmentCreditId,isOwnCaseStudy:isOwnCaseStudy} })
        .then((response) => {
            return response.data;
        });
}


const GetNoOfQuestionsPerAssessment = (id) => {

    return axios
        .get(API_URL + "GetNoOfQuestionsPerAssessment", { params: { assessmentId: id } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const CheckIfTheAssessmentIsLocked = (userId, assessmentId) => {

    return axios
        .get(API_URL + "CheckIfTheAssessmentIsLocked", { params: { userId: userId, assessmentId: assessmentId } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const IsTimerExhausted = (dateOriginal, dateToCompare) => {

    return axios
        .get(API_URL + "IsTimerExhausted", { params: { dateOriginal: dateOriginal, dateCurrent: dateToCompare } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetAssessmentResultReport = (assessmentId, userId,guid) => {

    return axios
        .get(API_URL + "GetAssessmentResultReport", { params: { assessmentId: assessmentId, userId: userId, guid:guid } })
        .then((response) => {
            //console.log(response.data.Message);
            return response.data;
        });
};

const GetAiAssessmentCategoryList = (company_id) => {
    return axios
        .post(API_URL + "GetAiAssessmentCategoryList",{companyId:company_id})
        .then((response) => {
            return response.data;
        });
};

const GetAiAssessmentList = (company_id,categoryId) => {
    return axios
        .post(API_URL + "GetAiAssessmentList",{companyId:company_id,categoryId:categoryId})
        .then((response) => {
            return response.data;
        });
};

const CreateAssessmentFile = (data) => {
    return axios
        .post(API_URL + "CreateAssessmentFile",{"queryList":data})
        .then((response) => {
            return response.data;
        });
};

const CreateSimpleAssessmentFile = (data) => {
    return axios
        .post(API_URL + "CreateSimpleAssessmentFile",{"queryList":data})
        .then((response) => {
            return response.data;
        });
};

const GetReportLink = (userId,assessmentId,creditId) => {
    return axios
        .post(API_URL + "GetReportLink",{"userId":userId,"assessmentId":assessmentId,"creditId":creditId})
        .then((response) => {
            return response.data;
        });
};

const GetAssessmentNameListByUserId = (userId) => {
    return axios
        .post(API_URL + "GetAssessmentNameListByUserId",{"userId":userId})
        .then((response) => {
            return response.data;
        });
};

const AssignAssessment = (userId,assignUserId,assessmentIdList) => {
    return axios
        .post(API_URL + "AssignAssessment",{"userId":userId,"assignUserId":assignUserId,"assessmentIdList":assessmentIdList})
        .then((response) => {
            return response.data;
        });
};

const UnblockAssessment = (userId,assessmentId,creditId) => {
    return axios
        .post(API_URL + "UnblockAssessment",{"userId":userId,"assessmentId":assessmentId,"creditId":creditId})
        .then((response) => {
            return response.data;
        });
};

const GetAssessmentCompletePercent = (userId,companyId,startDate,endDate) => {
    return axios
        .post(API_URL + "GetAssessmentCompletePercent",{"userId":userId,"companyId":companyId,"startDate":startDate,"endDate":endDate})
        .then((response) => {
            return response.data;
        });
};

const GetAssessmentCredits = (userId) => {
    return axios
        .post(API_URL + "GetAssessmentCredits",{"userId":userId})
        .then((response) => {
            return response.data;
        });
};

const GetSingleQuestionAnswer = (userId,assessmentId, creditId,topicId,questionId) => {
    return axios
        .post(API_URL + "GetSingleQuestionAnswer",{"userId":userId,"assessmentId":assessmentId,"creditId":creditId,"topicId":topicId,"questionId":questionId})
        .then((response) => {
            return response.data;
        });
};

const ReGenerateReport = (data) => {
    return axios
        .post(API_URL + "ReGenerateReport",{"queryList":data})
        .then((response) => {
            return response.data;
        });
};

const CheckAssignAssessorStatus = (data) => {
    return axios
        .post(API_URL + "CheckAssignAssessorStatus",{"AssignAssessorList":data})
        .then((response) => {
            return response.data;
        });
};

const AssignAssessor = (data) => {
    return axios
        .post(API_URL + "AssignAssessor",{"AssignAssessorList":data})
        .then((response) => {
            return response.data;
        });
};

const AddAssessmentResultShare = (ReportId,firstName,lastName,emailAddress,companyName,person,textArea,FirstName,LastName) => {
    return axios
        .post(API_URL + "AddAssessmentResultShare",{"ReportId":ReportId,'FirstName':firstName,'LastName':lastName,'Email':emailAddress,'Company':companyName,'Relationship':person,'EmailContent':textArea,'UserFirstName':FirstName,'UserLastName':LastName})
        .then((response) => {
            return response.data;
        });
};

export default {
    CreateAssessmentFile,
    GetAiAssessmentList,
    GetAiAssessmentCategoryList,
    GetCurrentDateAndTime, 
    GetAllAssessmentDetails, 
    GetAssessments, 
    GetAllAssessmentList, 
    GetAssessorList,
    GetNoOfQuestionsPerAssessment, 
    AddEntryInUserTestTable, 
    AddUserAssessmentProgress, 
    GetReviewAnsweredQuestions, 
    IsTimerExhausted, 
    GetCurrentDateAndTimeFromServer, 
    GetCurrentAssessmentSessionValues, 
    SetPendingTimeForTimer, 
    GetPendingTimeForTimer, 
    UpdateUserTestTableNew, 
    AssignTheAssessmentToUser,
     CheckIfTheAssessmentIsLocked,
     GetAssessmentResultReport,
     GetReportLink,
     GetAssessmentNameListByUserId,
     AssignAssessment,
     UnblockAssessment,
     GetAssessmentCompletePercent,
     GetAssessmentCredits,
     CreateSimpleAssessmentFile,
     GetSingleQuestionAnswer,
     ReGenerateReport,
     AddAssessmentResultShare,
     GetAllAssessmentListByCreditId,
     GetCaseStudyDetailsByAssessmentID,
     AddCaseStudyList,
     GetAllAssessmentDetailsByCreditID,
     GetAssessmentUserDocumentList,
     GetAssessorQuestionAnswerList,
     DeleteAssessmentUserDocument,
     UpdateCaseStudy,
     GetAssessorUploadFilesList,
     DeleteAssessorUploadFile,
     SaveAssessorAssessmentAnswers,
     CheckAssignAssessorStatus,
     GetAssessorUserList,
     AssignAssessor,
     GetUserUploadFilesList
};