import React, { useEffect, useState, useRef } from "react";

// Services
import AuthService from "../../../services/auth.service.js";
import CompanyService from "../../../services/companies.service.js";
import assessmentService from "../../../services/assessment.service.js";


// Components
import { Card, CardBody, Col, Row } from "reactstrap";
import { message, Upload, Input, Radio, Modal } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import WordCountInput from '../../../components/WordCountInput.js'
import  style  from '../style/AssessmentStyle/CaseStudyQuiz.module.css'
import PdfViewer from '../../../components/PdfViewer.js';
import  Link  from "../../../assets/img/icons/Link.png";
import styles from "../style/AssessmentStyle/StartAssessment.module.css";
import {toast} from 'react-toastify';

const { TextArea } = Input;
/*
* Time Based assessment view
*
* This view is responsible for displaying assessment questions & recording the answers
*
* TODO: Combine non time based & time based to a single component
*
* */
const QuizView = (props) => {
    sessionStorage.removeItem("ReSubmitQuestionKey");
    sessionStorage.removeItem("ReviewAssessmentKey");

    var currentUser = AuthService.getCurrentUser();
    let AssessmentID = props.location.state.AssessmentID
    var userId = currentUser.id;
    var Data = props.location.state.Data;
    var CreditId = props.location.state.CreditId;
    var Status = props.location.state.Status;
    let CaseStudyDetails = props.location.state.DocumentList;
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }

    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: "/Assessments",
        });
        window.location.reload();
    }
    if (props.history.action == "POP") {
        var isKeyPresent = JSON.parse(sessionStorage.getItem("AssessmentQuizKey"));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: "/Assessments",
            });
            window.location.reload();
        }
    }
    const { Dragger } = Upload;
    
    const updateFile = () => {
        var fileData = [];
        assessmentService.GetAssessorUploadFilesList(props.location.state.CreditId).then((response)=>{
            if(response !== null){
                response.map((item)=>{
                    fileData.push({
                        uid: item.documentId,
                        name: item.documentTitle,
                        status: 'done',
                      });
                })
                setFileList(fileData);
            }
        })
    }

    const Props = {
        name: 'file',
        accept:".doc,.docx,application/pdf,.xlsx",
        disabled: Status,
        customRequest(e) {
            const { onSuccess, onError, file } = e;
            var changeFiledata = fileList;
            const fileTypes = [
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word .docx
                'application/msword', // Word .doc
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel .xlsx
                'application/vnd.ms-excel' // Excel .xls（较旧版本）
            ];
            if(fileTypes.includes(file.type)){
                CompanyService.UploadFilePost(9,props.location.state.CreditId,file).then((response)=>{
                    if(response.data.Status !== "Success"){
                        onError();
                        toast.error(`${file.name} file upload failed.`,{position: toast.POSITION.TOP_CENTER});
                        updateFile();
                    }else{
                        onSuccess();
                        setFileList(changeFiledata); 
                        toast.success(`${file.name} file uploaded successfully.`,{position: toast.POSITION.TOP_CENTER});
                        updateFile();
                    }
                })
            }else{
                toast.error(`Format error, please upload PDF, Word or Excel file!`,{position: toast.POSITION.TOP_CENTER});
            }  
        },
        onChange(info) {
            const { status } = info.file;
            console.log(info);
            if (status !== 'uploading') {
              console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                console.log(info);
              message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
              message.error(`${info.file.name} file upload failed.`);
            }
        },

        onDrop(e) {
        },
        onRemove(e){
            setIsModalOpen(true);
            setDeleteId(e.uid);
        }
    };
    const [answer,setanswer] = useState([])
    const [pdfData,setpdfData] = useState('')
    const [fileList,setFileList] = useState([])
    const [isPassed,setIsPassed] = useState(Data[0].isPass == "False" ? false : (Data[0].isPass == "True" ? true : ''));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deteleId,setDeleteId] = useState('');

    const handleOk = () => {
        let deleteFiledata = fileList;
        assessmentService.DeleteAssessorUploadFile(deteleId).then((response)=>{
            if(response.Status == 'Success'){
                deleteFiledata = deleteFiledata.filter((item)=>{
                    return item.uid !== deteleId;
                })
                setFileList(deleteFiledata);
                toast.success(`Document has been removed.`,{position: toast.POSITION.TOP_CENTER});
            }else{
                toast.error(`${response.Message}`,{position: toast.POSITION.TOP_CENTER});
            }
        })
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
        const onChangeisPassed = (e) => {
        setIsPassed(e.target.value);
    };

    useEffect(() => {
        var answerData = [];
        var DocumentData = '';

        for(var i=0;i<props.location.state.Data.length;i++){
            answerData[i] = {
                questionId: props.location.state.Data[i].questionId,
                questionAnswer: props.location.state.Data[i].questionAnswer,
            }
        }
        // CaseStudyDetails.splice(0,0,{
        //     DocumentUrl:Data[0].reportLink,
        //     TitleText:'report.xlsx',
        //     ViewDocument:false
        // });
        for(var i=0;i<CaseStudyDetails.length;i++){
           if(CaseStudyDetails[i].ViewDocument === true){
                DocumentData = 'data:application/pdf;base64,' + CaseStudyDetails[i].DocumentString;
                // CaseStudyDetails.AssessmentDocumentList.splice(i,1) ;
                break;
           }
        }

        updateFile()
        setanswer(answerData); 
        setpdfData(DocumentData);
    }, []);

 

    // TODO: remove the following & use moment.js to handle time
    const formatToHoursAndMinutes = (secondsVal) => {
        if (secondsVal < 0) {
            secondsVal = Math.abs(secondsVal);
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v,i) => (v < 10 ? "0" + v : v))
                .filter((v, i) =>  v !== "00" || i > 0)
                .join(":");

        }else{
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v) => (v < 10 ? "0" + v : v))
                .filter((v, i) => v !== "00" || i > 0)
                .join(":");
        }
        
    };

    const CaseStudyonChange = (item,e) => {
        var value = answer;
        for (let i = 0; i < value.length; i++) {
            if (value[i].questionId == item.questionId) {
              value[i].questionAnswer = e;
              break;
            }
        }
        setanswer(value);
    };
    const CaseStudySubmit = (isSave ) => {
        const IsConform = answer.every(item => item.questionAnswer !== '');
        var answerDatas = answer;
        if(isPassed == '' && isPassed !== false){
            toast.error('Please select the final grading before submitting the assessment', {position: toast.POSITION.TOP_CENTER})
        }else{
            if(isSave){
                assessmentService.SaveAssessorAssessmentAnswers(props.location.state.userId,AssessmentID,CreditId,answerDatas,isSave,isPassed).then((response)=>{
                    if(response.Status === "Success"){
                        assessmentService.GetAllAssessmentListByCreditId(userId, props.location.state.AssessmentID, props.location.state.CreditId).then((response1) =>{
                            if(response1 !== null) {
                                toast.success('Save success!', {position: toast.POSITION.TOP_CENTER})
                            }
                            
                        })
                    }
                }).catch((error)=>{
                    console.log(error);
                });
            }else{
                if(IsConform){
                    assessmentService.SaveAssessorAssessmentAnswers(props.location.state.userId,AssessmentID,CreditId,answerDatas,isSave,isPassed).then((response)=>{
                        if(response.Status === "Success"){
                            assessmentService.GetAllAssessmentListByCreditId(userId, props.location.state.AssessmentID, props.location.state.CreditId).then((response1) =>{
                                if(response1 !== null) {
                                        props.history.push({
                                        pathname: '/Assessments'
                                    })
                                }
                                
                            })
                        }
                    }).catch((error)=>{
                        console.log(error);
                    });
                }else{
                    toast.error('Please fill all the answers', {position: toast.POSITION.TOP_CENTER})
                }
            }
        }

      
    }
    const DownloadPdf = (url) => {
        const a = document.createElement('a');
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    return (
        <>
            <Row>
                <div className="col-lg-8 col-md-9">
                <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <p>Confirm to delete the uploaded file?</p>
                </Modal>
                    <Card
                        className="my-card"
                        id="assessment-wrapper-list"
                        style={{padding:'2rem'}}
                    >
                        <div className={style.PDF}>
                            <PdfViewer data={pdfData} />
                        </div>
                        <div className={style.questions}>
                        {Data.map((item,index)=>{
                            return(
                                <Row key={index} className={styles.introduce_item}>
                                    <div className="col-lg-1 col-md-1" style={{display: 'flex',justifyContent: 'center'}}>
                                        <div className="question-number rounded-circle text-white font-weight-bold h3" style={{flexShrink: '0'}}>
                                        {index+1}
                                        </div>
                                    </div>
                                    <div className="col-lg-11 col-md-11 ml-0" >
                                        <div className=" font-weight-bold h3"style={{}}>{item.questionTitle}</div>
                                        <div dangerouslySetInnerHTML={{__html:item.questionDescription}}>

                                        </div>
                                        <div className={style.answer}>
                                        <WordCountInput 
                                            display={Status}
                                            placeholder="Type your answer here"
                                            maxWords={500}
                                            value={Data[index].questionAnswer}
                                            onChange={(e)=>{
                                                CaseStudyonChange(item,e)}}
                                        />
                                        </div>
                                    </div>
                                </Row>
                            )
                        })}
                         {<div style={{fontWeight: 'bold',textAlign: 'center'}}>
                            <p style={{margin:'1rem 0',fontSize: '20px',color:'rgba(0, 0, 0, 0.88)'}}>Please select if the student passed/failed the assessment.</p>
                            <Radio.Group disabled={Status} onChange={onChangeisPassed} value={isPassed}  >
                                <Radio value={true} >
                                    <span style={{}}>Assessment Passed</span>
                                </Radio>
                                <Radio value={false}>
                                    <span style={{}}>Assessment Failed</span>
                                </Radio>
                            </Radio.Group>
                        </div>}
                        </div>
                    </Card>
 
                </div>
                <div className={`col-lg-4 col-md-3 ${style.sidebar}`}>
                    <Card
                        className="my-card"
                        id="assessment-wrapper-list"
                        style={{padding:'2rem 0'}}
                    >   
                    <CardBody className="py-0 scrollbar1"  style={{overflowY: "scroll",marginBottom:'2rem'}}>
                        <span style={{fontSize:'30px',fontWeight:'bold',color:'#000000'}}>
                            Instructions
                        </span>
                        <hr/>
                        {Data.map((item,index)=>{
                            if(index == 0){
                                return(
                                    <div key={index} dangerouslySetInnerHTML={{__html:item.instructions}}>
                                    </div>
                                )
                             }
                        })
                        }
                        
                    </CardBody>
                    <CardBody className="py-0 scrollbar1"  style={{overflowY: "scroll",marginBottom:'2rem'}}>
                        <span style={{fontSize:'30px',fontWeight:'bold',color:'#000000'}}>
                        User Submitted Documents
                        </span>
                        <hr/>
                        <div className="my-sidenav">
                            <div className="d-flex" style={{height:'2rem',marginBottom: '1rem'}}>
                                <div className="LinkIcon">
                                    <img src={Link} alt="linkicon" style={{height:'100%',marginRight:'1rem'}} />
                                </div>
                                <div className={style.LinkName} onClick={()=>{
                                    DownloadPdf(Data[0].reportLink)
                                }}>
                                    report.xlsx
                                </div>
                            </div>
                            {CaseStudyDetails.map((item,index)=>{
                                return(
                                    <div className="d-flex" key={index} style={{height:'2rem',marginBottom: '1rem'}}>
                                        <div className="LinkIcon">
                                            <img src={Link} alt="linkicon" style={{height:'100%',marginRight:'1rem'}} />
                                        </div>
                                        <div className={style.LinkName} onClick={()=>{
                                            DownloadPdf(item.DocumentUrl)
                                        }}>
                                            {item.TitleText}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </CardBody>
                    <CardBody className="py-0 scrollbar1"  style={{overflowY: "scroll"}}>
                        <Row>
                            <Col md={12} sm={12}>
                                <div className="my-sidenav">
                                <span style={{fontSize:'30px',fontWeight:'bold',color:'#000000'}}>
                                    Supporting Documents
                                </span>
                                <hr/>
                                    <Dragger {...Props} fileList={fileList}>
                                        <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                        banned files.<br/>Supported formats: .pdf, .docx, .xls, .xlxs
                                        </p>
                                    </Dragger>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    {!Status && <div style={{padding:'0 20px'}}>
                            <button
                                id="submit-button"
                                className="quiz-submit-btn btn btn-primary mr-1"
                                style={{marginBottom:'1rem',marginTop:'2rem',width:'100%',backgroundColor:'var(--theme_color1)'}}
                                onClick={()=>{
                                    CaseStudySubmit(true)}}
                            >
                                Save
                            </button>
                            <button
                                id="submit-button"
                                className="quiz-submit-btn btn btn-primary mr-1"
                                style={{marginBottom:'1rem',width:'100%'}}
                                onClick={()=>{
                                    CaseStudySubmit(false)}}
                            >
                                Submit Grading
                            </button>
                    </div>}
                    </Card>
                </div>
            </Row>
        </>
    );
};

const CaseStudyQuiz = (props) => (
    <>
        <QuizView {...props} />
    </>
);

export default CaseStudyQuiz;
